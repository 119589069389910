/* large tablets and laptop styles */
@media screen and (min-width: 901px) {
  .login {
    background-color: white;
  }

  .login-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    width: 100%;
    min-height: calc(100vh - 101px);
  }

  .login-modal {
    background-color: white;
    width: 100%;
    max-width: 400px;
    padding: 40px 50px;
    border: solid 1px white;
    border-radius: 15px;
    position: relative;
    z-index: 2;
  }

  .background-image {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: url("../images/Off-White.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
}

.login img {
  grid-column: 3/7;
  grid-row: 1;
  padding: 40px;
  margin-top: 40px;
  max-width: 250px;
}

.login h3 {
  grid-column: 1/9;
  grid-row: 2;
  padding: 5px;
  max-width: 100%;
}

.login form {
  grid-column: 1/9;
  grid-row: 3;
  margin: 0px;
  padding: 0px;
  border: 0px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.login input {
  padding: 8px 20px;
  font-size: 1em;
  color: #777;
  outline: 1px solid green;
}

.login button {
  padding: 8px 6px;
  font-size: 1em;
  border-radius: 9999px;
  border: none;
  height: 40px;
}

.login-button {
  background-color: var(--primary);
  color: white;
}

.accountType-button {
  background-color: var(--primary);
  color: white;
  padding: 10px;
}

.accountType-flex {
  display: flex;
  gap: 10px;
  grid-row: 3;
  grid-column: 1/9;
}

.signup-note {
  font-family: var(--secondaryFont);
  color: gray;
  font-size: 14px;
  text-align: center;
  max-width: 200px;
  padding-left: 15px;
}

.signup-error {
  font-family: var(--secondaryFont);
  color: gray;
  font-size: 14px;
  text-align: center;
  max-width: 200px;
  padding-left: 15px;
  font-style: italic;
}

.login-benefits-flex {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  font-weight: bold;
  color: gray;
  grid-column: 1/9;
  grid-row: 4;
  padding-top: 20px;
  text-align: center;
}

.login-serving-parsons {
  gap: 15px;
  color: gray;
  grid-column: 1/9;
  grid-row: 5;
  padding: 5px;
  text-align: center;
  padding-top: 20px;
}

.login-business-description {
  gap: 15px;
  color: gray;
  grid-column: 1/9;
  grid-row: 6;
  padding-top: 30px;
}

.login-error {
  text-align: center;
  color: gray;
  font-style: italic;
  max-width: 200px;
  padding-left: 15px;
}

.login p {
  max-width: 200px;
  margin: 10px;
}

.permissions {
  font-size: 12px;
  margin: 0;
}

.permissions h3 {
  font-size: 16px;
  margin: 0;
}

.permissions p {
  max-width: 500px;
  font-size: 12px;
}

.permissions input:checked {
  background-color: green;
}
