@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

/* variables */
:root {
  --primary: #359356;
  --secondary: #ffde59;
  --primaryFont: "Bitter", serif;
  --secondaryFont: "Open Sans", sans-serif;
}

/* reset */
body,
p,
a,
ul,
li {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: var(--secondaryFont);
}

a:link,
a:visited {
  color: green;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: gray;
  background-color: transparent;
  text-decoration: underline;
}

li {
  list-style-type: none;
}

/* base styles */

body {
  background-color: #ffffff;
  overflow-x: hidden;
}

h3 {
  text-align: center;
  color: var(--primary);
  font-family: "Bitter", serif;
  font-family: var(--primaryFont);
}

h1 {
  text-align: center;
  color: var(--primary);
  font-family: var(--primaryFont);
}

.button {
  background: var(--primary);
  border: 2px solid var(--primary);
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 40px;
  border-radius: 9999px;
  transition-duration: 0.4s;
  margin: 20px;
}

.button:hover {
  background-color: #ffffff;
  /* Green */
  color: var(--primary);
  border: solid;
  font-weight: bold;
}

input,
textarea {
  background: #ffffff;
  padding: 10px, 16px;
  border-radius: 20px;
  border: 1px solid black;
  color: #000000;
}

.loading-spinner {
  color: var(--primary);
  text-align: center;
  margin-top: 35px;
}

/* fonts */

/* mobile styles */
.grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-items: center;
}

/* small tablet styles */
@media screen and (min-width: 620px) {
}

/* large tablets and laptop styles */
@media screen and (min-width: 960px) {
}

/* desktop styles */
@media screen and (min-width: 1200px) {
}
