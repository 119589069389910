.admin-card-markup {
  font-size: 20px;
  width: 100px;
  text-align: center;
  margin-top: 10px;
}

.highlight {
  background: var(--secondary);
}

.admin-card-button-flex {
  display: flex;
  justify-content: flex-end;
}

.admin-card-remove {
  background: white;
  color: var(--primary);
  font-size: 12px;
  padding: 10px;
}

.admin-card-addition {
  background-color: white;
  border: 1px lightgray solid;
  color: black;
  border-radius: 10%;
}

.admin-card-addition button {
  margin: 6px;
  color: black;
  background-color: #f4f4f4f4;
}

.admin-card-addition:hover button {
  margin: 6px;
  color: black;
  background-color: #f4f4f4f4;
}

.admin-add-item {
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: var(green);
}

.admin-card .wholesale-price {
  position: absolute;
  top: 5px;
  right: 5px;
}

.admin-card-reject {
  border: 3px rgb(235, 0, 0) solid;
}

.admin-card-add {
  border: 3px var(--primary) solid;
}

.admin-card .product-description {
  cursor: pointer;
}

.hide {
  display: none;
}
