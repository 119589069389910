.product-grid {
  display: grid;
  background-color: #f8f7f5;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-auto-rows: minmax(200px, auto);
  align-items: center;
}

.cell {
  background-color: #ffffff;
  text-align: center;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.05);
  font-family: var(--secondaryFont);
  margin: 10px;
  align-self: stretch;
  position: relative;
}

.item-photo {
  height: 140px;
  width: 140px;
  object-fit: contain;
  cursor: zoom-in;
}

.addition {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  color: #ffffff;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  margin: 20px auto;
  height: 40px;
  width: 164px;
  font-size: 16px;
  border-radius: 20px;
}

.addition button {
  margin: 20px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 20px;
  padding: 5px 20px 5px 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
}

.addition button:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.home .results {
  font-family: var(--secondaryFont);
  padding: 5px;
}

.pageNavigation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upper-pageNavigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: relative;
  bottom: 0px;
  width: 100%;
  height: 75px;
}

.chevron {
  color: var(--primary);
  max-width: 100%;
  height: 24px;
  width: 24px;
  padding: 10px;
  cursor: pointer;
}

.disabled-chevron {
  color: lightgray;
  max-width: 100%;
  height: 24px;
  width: 24px;
  padding: 10px;
  cursor: pointer;
}

.item-grid {
  display: flex;
  align-items: center;
}

.product-description {
  font-size: 15px;
  color: black;
  font-family: "Bogle", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  text-align: left;
  padding: 5px 5px 10px 10px;
}

.product-price {
  font-size: 20px;
  color: black;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bold;
  text-align: left;
  max-width: 134px;
}

.price-per-unit {
  font-size: 15px;
  color: gray;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: left;
}

.item-price {
  font-size: 15px;
  color: gray;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: left;
  font-weight: normal;
}

.price {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 10px 0px 5px 10px;
}

.temperature-icon {
  color: var(--primary);
  height: 25px;
  border-radius: 50%;
  padding-top: 2px;
  cursor: pointer;
}

.show {
  display: none;
  position: absolute;
  bottom: 35px;
}

.icon-description {
  font-size: 14px;
  color: gray;
  position: relative;
  padding-bottom: 20px;
  cursor: pointer;
}

.cell-container {
  font-size: 14px;
  text-align: left;
  padding: 0px 10px;
}

.cell-subContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.cases {
  padding-top: 15px;
  text-align: center;
  font-size: 14px;
}

.item-score {
  font-size: 12px;
  color: gray;
}

.white-circle {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 12px;
  left: 12px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 25%;
}

.newItem {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.cell-product-id {
  display: none;
}

.lightboxCaption {
  position: fixed;
  margin-bottom: 6px;
  color: gray;
  font-weight: 600px;
  font-size: 16px;
  z-index: 1000000001;
  width: 100%;
  height: 100%;
}

.hide {
  display: none;
}

.faMagnifyingGlassPlus {
  color: rgba(150, 150, 150, 0.3);
  height: 20px;
}

.popular-item {
  padding-bottom: 15px;
  font-size: small;
  color: gray;
}

.popular-item-number {
  color: var(--primary);
  font-size: 2em;
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 25%;
  font-weight: bo;
}

/* small tablet styles */
@media screen and (min-width: 512px) {
  .item-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .item-photo {
    height: 140px;
    width: 140px;
    object-fit: contain;
    padding-top: 10px;
  }

  .product-description {
    font-size: 15px;
    color: black;
    font-family: "Bogle", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
    text-align: left;
    padding: 5px 0px 10px 10px;
    min-height: 50px;
    cursor: pointer;
  }

  .text-copied {
    padding: 0;
    margin: 0;
    font-family: "Bogle", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
    font-size: 14px;
  }

  .cell-container {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    padding-right: 15px;
    font-size: 14px;
  }

  .product-price {
    max-width: none;
  }
}

@media screen and (min-width: 1279px) {
  .product-grid {
    display: grid;
    background-color: #f8f7f5;
    grid-template-columns: repeat(4, 18rem);
    grid-auto-rows: minmax(200px, auto);
    justify-content: center;
  }
}

@media print {
  .product-filter,
  .logo,
  .navbar-notification-link,
  .navbar,
  .upper-pageNavigation,
  .button {
    display: none;
  }

  .product-grid {
    display: block;
  }

  .cell {
    float: left;
    width: 300px;
    height: 200px;
    padding: 10px;
    break-inside: avoid;
    box-shadow: none;
    border: 1px gray solid;
    margin: 0px;
  }

  .cell-product-id {
    display: block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
}
